import { DecoratorE } from "@pihanga2/cards/src"
import {
  PiCardDef,
  PiCardRef,
  createShowPageAction,
  memo,
} from "@pihanga2/core"
import { JyPageD2 } from "@pihanga2/joy-ui"
import { Icon } from "./app.icons"
import { AppState } from "./app.state"
import { BreadCrumb } from "@pihanga2/cards/src/breadcrumbs"
import { ItemClickedEvent } from "@pihanga2/cards/src/list"
import { UserInfoEvent } from "@pihanga2/ivcap"
import { UserProps } from "@pihanga2/joy-ui/src/pageD2/pageD2.types"
import { TITLE } from "."

type MenuDecl = [string, string, Icon]

const PRIMARY_MENU: MenuDecl[] = [
  // ["home", "Home", Icon.Home],
  ["orders", "Orders", Icon.Order],
  ["artifacts", "Artifacts", Icon.Artifact],
  // ["services", "Services", Icon.Service],
  // ["entities", "Entities", Icon.Entity],
]

const SECONDARY_MENU: MenuDecl[] = []

export function page(content: PiCardRef, actionCard?: PiCardRef): PiCardDef {
  return JyPageD2<AppState>({
    headerTitle: TITLE,
    pageTitle: ({ route }) => {
      const p = route.path || []
      return p.length === 0 ? "Home" : capitalize(p[0])
    },
    content: ({ route }) => {
      const p = route.path || []
      switch (p.length) {
        case 0:
          return "home"
        case 1:
          return `${p[0]}/list` // `${capitalize(p[0])}ListCard`
        case 2:
          return `${p[0]}/record` // `${capitalize(p[0])}DetailCard`
        default:
          return "UnknownCard"
      }
    },
    modal: (s) => s.page.modalCard,
    breadcrumbs: memo<string[], BreadCrumb[], AppState, any>(
      ({ route }) => route.path,
      (path) => {
        const p = path || []
        const ba: BreadCrumb[] = [
          { id: "home", decorator: { type: DecoratorE.Icon, icon: Icon.Home } },
        ]
        if (p.length > 0) {
          const id = p[0]
          ba.push({ id, label: capitalize(id) })
        }
        if (p.length > 1) {
          const id = p[1]
          ba.push({ id, label: capitalize(id) })
        }
        return ba
      },
    ),
    logoIcon: Icon.Logo,
    user: memo<UserInfoEvent | undefined, UserProps, AppState, any>(
      (s) => s.user,
      (user) => ({
        name: user?.nickName || user?.name || "anonymous",
        email: user?.email,
      }),
    ),
    actionCard: ({ route }) => {
      const p = route.path || []
      switch (p.length) {
        case 0:
          return "HomeActionCard"
        case 1:
          return `${p[0]}/list/actions` // `${capitalize(p[0])}ListActionCard`
        case 2:
          return `${p[0]}/record/actions` // `${capitalize(p[0])}DetailActionCard`
        default:
          return "UnknownCard"
      }
    },
    onMainMenuItemClicked: (s, { itemID }) => {
      console.log("MENU ITEM CLICKED", itemID)
      return s
    },
    mainMenu: PRIMARY_MENU.map(([id, title, icon]) => ({
      id,
      title,
      startDecorator: { type: DecoratorE.Icon, icon },
      // onItemClickedMapper: (a) => {
      //   return s
      // },
    })),
    onMainMenuItemClickedMapper: ({ itemID }: ItemClickedEvent) =>
      createShowPageAction([itemID]),

    secondaryMenu: SECONDARY_MENU.map(([id, title, icon]) => ({
      id,
      title,
      startDecorator: { type: DecoratorE.Icon, icon },
    })),
  })
}

function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1)
}
