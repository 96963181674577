import { ReduxAction, createOnAction, registerActions } from "@pihanga2/core"
import { URN } from "@pihanga2/ivcap"

export const ORDER_ACTION = registerActions("orders", [
  "preview",
  "record/aspects",
  // "CREATE_NEW_PROJECT",
  // "SELECT_PROJECT",
  // "SHOW_LISTING",
  // "SHOW_INDIVIDUAL",
  // "LISTING",
  // "EDIT_PROJECT",
  // "DELETE_PROJECT",
  "error",
])

export type PreviewOrderEvent = { orderID: URN }

export const onPreviewOrder = createOnAction<PreviewOrderEvent>(
  ORDER_ACTION.PREVIEW,
)

export const createPreviewAction = (
  orderID: URN,
): ReduxAction & PreviewOrderEvent => ({
  type: ORDER_ACTION.PREVIEW,
  orderID,
})

//---

export type OrderAspectsEvent = {
  recordID: string
  showing: boolean
}

export const onOrderAspects = createOnAction<OrderAspectsEvent>(
  ORDER_ACTION["RECORD/ASPECTS"],
)

export const createOrderAspectsAction = (
  recordID: string,
  showing: boolean,
): ReduxAction & OrderAspectsEvent => ({
  type: ORDER_ACTION["RECORD/ASPECTS"],
  recordID,
  showing,
})

export const onCreateOrderAspects = createOnAction<OrderAspectsEvent>(
  ORDER_ACTION["RECORD/ASPECTS"],
)
