import {
  AspectListItem,
  OrderListItem,
  OrderRecord,
  URN,
} from "@pihanga2/ivcap"
import { AppState } from "../app.state"
import { ListState } from "../app.types"
import { FetchedRecord } from "../common.pihanga"

export type OrderListState = ListState<OrderListItem>

export type OrderState = AppState & {
  orders: {
    list: OrderListState
    // list: {
    //   items: OrderListItem[]
    //   itemCount: number // used for paging
    //   offset: number
    //   prevPage?: string
    //   sortColumn?: string
    //   sortAscending?: boolean
    // } & Partial<PageLinks>
    records: { [id: string]: FetchedOrderRecord }
    recordShown?: string
    showInModal?: URN
  }
}

export type FetchedOrderRecord = FetchedRecord & {
  record?: OrderRecord
  // content?: FetchedOrderData
  // contentDownloadPending: boolean
  metadata?: AspectListItem[]
  metadataDownloadPending: boolean
}

// export type OrderState = AppState & {
//   orders: {
//     list: {
//       items: OrderListItem[]
//       offset: number
//       nextPage?: string
//       prevPage?: string
//     }
//     records: { [id: string]: FetchedOrderRecord }
//     data: { [id: string]: FetchedOrderData }
//     recordShown?: string
//     showInModal?: URN
//   }
// }


// export type FetchedRecord<T> = {
//   id: string
//   record?: T
//   fetchedAt?: number
//   isPending: boolean
// }

// export type FetchedOrderRecord = FetchedRecord<OrderRecord> & {
//   // id: string
//   // record?: OrderRecord
//   aspects?: AspectListItem[]
//   // fetchedAt?: number
//   // isPending: boolean
//   data?: any

//   dataAccessed?: number
// }

// export type FetchedOrderData = {
//   id: string
//   data?: any
//   mimeType?: string
//   lastAccessed?: number
//   fetchedAt?: number
//   isPending: boolean
// }

export enum OrderCard {
  Main = "orders",
  List = "orders/list",
  ListDetail = "orders/list/detail",
  ListAction = "orders/list/action",
  Record = "orders/record",
  Upload = "orders/upload",
}
