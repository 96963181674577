import SvgIcon from "@mui/joy/SvgIcon"
import { memo } from "react"

import { registerMuiIcon } from "@pihanga2/joy-ui"
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded"
import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded"
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded"
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded"
import SupportRoundedIcon from "@mui/icons-material/SupportRounded"
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded"

import CheckRoundedIcon from "@mui/icons-material/CheckRounded"
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded"
import BlockIcon from "@mui/icons-material/Block"
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded"
import AddIcon from "@mui/icons-material/Add"
import FolderOpenIcon from "@mui/icons-material/FolderOpen"
import CheckListIcon from "@mui/icons-material/CheckList"
import HandymanIcon from "@mui/icons-material/Handyman"
import EggIcon from "@mui/icons-material/Egg"

import { registerIcon } from "@pihanga2/cards/src/icons"

export enum Icon {
  Logo = registerIcon(memo(IvcapIcon), "ivcap"), // registerMuiIcon(DashboardRoundedIcon),

  Order = registerIcon(CheckListIcon),
  Artifact = registerIcon(FolderOpenIcon),
  Service = registerIcon(HandymanIcon),
  Entity = registerIcon(EggIcon),

  Download = registerMuiIcon(DownloadRoundedIcon),
  Home = registerMuiIcon(HomeRoundedIcon),
  Dashboard = registerMuiIcon(DashboardRoundedIcon),
  Task = registerMuiIcon(AssignmentRoundedIcon),
  Orders = registerMuiIcon(ShoppingCartRoundedIcon),
  Message = registerMuiIcon(QuestionAnswerRoundedIcon),
  Support = registerMuiIcon(SupportRoundedIcon),
  Setting = registerMuiIcon(SettingsRoundedIcon),

  Paid = registerMuiIcon(CheckRoundedIcon),
  Refunded = registerMuiIcon(AutorenewRoundedIcon),
  Cancelled = registerMuiIcon(BlockIcon),

  Add = registerMuiIcon(AddIcon),
}

function IvcapIcon() {
  return (
    <SvgIcon fontSize="xl">
      <g stroke="currentColor" fill="none">
        <path
          d="M 7 5 A 9.6 9.6, 0, 1, 0, 22 15 M 12 8 L 12 18"
          strokeWidth="3"
        />
        <circle cx="12" cy="3.5" r="2.5" strokeWidth="2" />
      </g>
    </SvgIcon>
  )
}
