import { Table, TableColumnTypeE, TableRow } from "@pihanga2/cards/src/table"
import { memo } from "@pihanga2/core"
import { StateMapperContext } from "@pihanga2/core/src/types"
import { ReduxState } from "@pihanga2/core"

export type NameValueCardRowDef = [name: string, value: any, type: string]

type NameValue = {
  name: string
  value: any
  type: string
}

export type FetchedRecord = {
  id: string
  fetchedAt?: number
  isPending: boolean
}

export type StateMapper<S, R extends FetchedRecord> = (
  state: S,
  context: StateMapperContext<any>,
) => R

export type DataMapper<R> = (record: R) => { [k: string]: any }

const DefDataMapper = <R>(r: R) => r as { [k: string]: any }

export function NameValueCard<S extends ReduxState, R extends FetchedRecord>(
  listDefF: (record: R) => NameValueCardRowDef[],
  stateMapper: StateMapper<S, R>,
  dateMapper: DataMapper<R> = DefDataMapper,
) {
  return Table<NameValue, S>()({
    columns: [
      {
        type: TableColumnTypeE.String,
        label: "name",
        columnWidth: "8em",
        fontWeight: "bold",
      },
      {
        type: TableColumnTypeE.String,
        label: "value",
        sortable: true,
      },
    ],
    hideColumnHeaders: true,
    sheetWrap: { notUsed: true },
    hoverRow: true,
    data: memo<R | undefined, TableRow<NameValue>[], S, any>(
      stateMapper,
      (record) => {
        if (!record || record.isPending) return []
        const rec = dateMapper(record)
        const listDef = listDefF(record)
        const rows = listDef.map(([id, name, type]) => {
          const value = rec[id] || "-"
          return {
            id: name,
            data: { name, value, type },
          }
        })
        // .sort((a, b) => a - b)
        return rows
      },
    ),
  })
}
