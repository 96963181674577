import {
  PiDefCtxtProps,
  PiMapProps,
  PiRegister,
  createShowPageAction,
  memo,
  showPage,
  PiCardRef,
  ReduxState,
  StateMapperContext,
} from "@pihanga2/core"
import {
  Table,
  TableColumnTypeE,
  TableEvents,
  TableProps,
  TableRow,
} from "@pihanga2/cards/src/table"
// import { ColumnType, TbDataTable } from "../tabler/cards/tbDataTable"
import { ArtifactState, FetchedArtifactRecord } from "./artifacts.types"
import { ArtifactListItem } from "@pihanga2/ivcap"
import { Button } from "@pihanga2/cards/src/button"
import { ButtonGroup } from "@pihanga2/cards/src/buttonGroup"
import {
  DEF_DATE_FORMATTER,
  DataGrid,
  DataGridEl,
  DataGridElTypeE,
} from "@pihanga2/cards/src/dataGrid"
import { createPreviewAction } from "./artifacts.action"
import { Modal } from "@pihanga2/cards/src/modal"
import { PageState } from "../app.state"
import { previewViewer } from "./artifacts.util"
import { Spinner } from "@pihanga2/cards/src/spinner"
import { getList } from "../app.reducer"

const ARTIFACT_ROOT = "artifacts"
export const ArtifactDetailCard = "artifacts/list/detail"

export function init(register: PiRegister): void {
  register.card(
    "artifacts/list",
    artifactList(
      (s: ArtifactState) => s.artifacts.list.items,
      {
        onColumnSort: (s, { col, isAscending }, d) => {
          s.artifacts.list.sortColumn = col.label
          s.artifacts.list.sortAscending = isAscending
          getList(undefined, s.artifacts.list, ARTIFACT_ROOT, d)
          return s
        },
        onNewPage: (s, a, d) => {
          getList(a.cursor as string, s.artifacts.list, ARTIFACT_ROOT, d)
          s.artifacts.list.items = []
          return s
        },
      },
      ArtifactDetailCard,
    ),
  )

  register.card(
    ArtifactDetailCard,
    DataGrid<ArtifactListItem>()<ArtifactState>({
      data: (s, { ctxtProps }) => {
        return ctxtProps.row.data
      },
      items: memo<ArtifactListItem, DataGridEl[], any, PiDefCtxtProps>(
        (_, { ctxtProps }) => ctxtProps.row.data as ArtifactListItem,
        (row) => [
          {
            id: "id",
            type: DataGridElTypeE.Text,
            //startDecorator: { type: DecoratorE.Icon, icon: Icon.Support },
            style: { root: { width: "100%" } },
          },
          { type: DataGridElTypeE.Separator },
          {
            id: "name",
            type: DataGridElTypeE.Text,
            noWrap: true,
          },
          {
            id: "mimeType",
            title: "Mime Type",
            type: DataGridElTypeE.Text,
          },
          {
            id: "size",
            type: DataGridElTypeE.Text,
          },
          {
            id: "status",
            type: DataGridElTypeE.Text,
          },
          { type: DataGridElTypeE.Separator },
          {
            id: "buttons",
            type: DataGridElTypeE.Card,
            cardName: ButtonGroup({
              buttons: [
                Button<ArtifactState>({
                  label: "Preview",
                  tooltip: "Preview artifact in popup",
                  variant: "solid",
                  onClickedMapper: () => createPreviewAction(row.id),
                }),
                Button({
                  label: "Details",
                  tooltip: `Show the Details page - ${row.name}`,
                  variant: "plain",
                  onClickedMapper: () =>
                    createShowPageAction(["artifacts", row.id]),
                }),
              ],
              ariaLabel: "buttons to manage artifact details card",
              size: "sm",
              spacing: "0.2em",
              variant: "plain",
              color: "primary",
            }),
            hideTitle: true,
            style: { root: { width: "100%" } },
          },
        ],
      ),
    }),
  )

  register.card(
    "artifacts/list/actions",
    ButtonGroup({
      ariaLabel: "action buttons",
      spacing: 1,
      variant: "plain",
      size: "sm",
      buttons: [
        // Button({
        //   label: "Order",
        //   tooltip: "Create new order",
        //   color: "primary",
        //   variant: "solid",
        //   startDecorator: { type: DecoratorE.Icon, icon: Icon.Add },
        //   onClicked: (s, a) => {
        //     console.log("NEW ORDER")
        //     return s
        //   },
        // }),
        // Button({
        //   label: "Download List",
        //   startDecorator: { type: DecoratorE.Icon, icon: Icon.Download },
        //   onClicked: (s, a) => {
        //     console.log("DOWNLOADING")
        //     return s
        //   },
        // }),
      ],
    }),
  )

  register.card(
    "artifacts/modal",
    Modal<ArtifactState & PageState>({
      title: (s) => {
        const id = s.artifacts.showInModal
        if (!id) return "???"
        const rec = s.artifacts.list.items.find((r) => r.id === id)
        if (!rec) return "???"
        const { name } = getArtifactName(rec)
        return name
      },
      open: (s) => !!s.artifacts.showInModal,
      contentCard: memo<
        FetchedArtifactRecord | undefined,
        PiCardRef,
        ArtifactState,
        any
      >(
        (s) => s.artifacts.records[s.artifacts.showInModal ?? ""],
        previewViewer,
      ),
      onClose: (s) => {
        s.page.modalCard = undefined
        return s
      },
    }),
  )
  // showInModal

  // register.card(ArtifactCard.Upload, PiFileDrop<ArtifactState>({})
}

export type ArtifactListMapper<S extends ReduxState> = (
  state: S,
  context: StateMapperContext<any>,
) => SimplifiedArtifactItem[]

export type SimplifiedArtifactItem = {
  id: string
  id_short?: string
  name: string
  status: string
  mimeType: string
}

export type ArtifactListProps<S extends ReduxState> = Omit<
  PiMapProps<
    TableProps<SimplifiedArtifactItem>,
    S,
    TableEvents<SimplifiedArtifactItem>
  >,
  "columns" | "data"
>

export function artifactList<S extends ReduxState>(
  dataMapper: ArtifactListMapper<S>,
  props: ArtifactListProps<ArtifactState>,
  detailCard: PiCardRef = ArtifactDetailCard,
  sortable = true,
) {
  return Table<SimplifiedArtifactItem, ArtifactState>()({
    columns: [
      { type: TableColumnTypeE.String, label: "id_short", title: "Id" },
      {
        type: TableColumnTypeE.String,
        label: "name",
        title: "Name",
        sortable,
      },
      {
        type: TableColumnTypeE.String,
        label: "mimeType",
        title: "Type",
        sortable,
      },
      {
        type: TableColumnTypeE.String,
        label: "status",
        title: "Status",
        sortable,
      },
    ],
    data: memo<SimplifiedArtifactItem[], any, any, any>(dataMapper, (items) =>
      items.map((el) => {
        const { id, id_short, name } = getArtifactName(el)
        return {
          id,
          data: { ...el, id_short, name },
          detailCard,
        }
      }),
    ),
    dataFormatter: {
      date: (el: any) => {
        return el ? DEF_DATE_FORMATTER.format(Date.parse(el)) : "-"
      },
    },
    rowsClickable: true,
    dataOffset: (s) => s.artifacts.list.offset,
    // cardOnEmpty: AppCard.Spinner,
    onRowClicked: (s, { row }, dispatch) => {
      showPage(dispatch, ["artifacts", row.id as string])
      return s
    },
    hasDetails: true,
    manageDetails: true,
    showSearch: false,
    showFooter: true,
    thisCursor: (s) => s.artifacts.list.thisPage,
    firstCursor: (s) => s.artifacts.list.firstPage,
    nextCursor: (s) => s.artifacts.list.nextPage,
    prevCursor: (s) => s.artifacts.list.prevPage,
    cardOnEmpty: Spinner({}),
    ...props,
  })
}

function getArtifactName(el: SimplifiedArtifactItem): {
  id: string
  id_short: string
  name: string
} {
  const id = el.id.split(":")[3]
  const id_short = id.substring(id.length - 6)
  let name = el.name.startsWith("urn:ivcap") ? id_short : el.name
  if (name.startsWith("http")) {
    // should no longer happen
    name = id_short
  }
  return { id, id_short, name }
}
