import { Order } from "@pihanga2/ivcap"
import { OrderState, FetchedOrderRecord } from "./orders.types"

export function fetchOrderRecord(
  recID: string,
  refresh: boolean, // if set, fetch record even if it already exists
  state: OrderState,
  ivcapAPI: Order<OrderState>,
  onFetched?: (rec: FetchedOrderRecord, state: OrderState) => void,
) {
  let rec = state.orders.records[recID]
  if (rec && (!refresh || rec.isPending)) {
    // already fetched or in the process of fetching
    return
  }
  // ok, fetch record from backend
  rec = {
    id: recID,
    isPending: true,
    metadataDownloadPending: false,
  }
  state.orders.records[recID] = rec
  ivcapAPI.get({ id: recID }).reduce((s, { order }) => {
    const rec: FetchedOrderRecord = {
      id: recID,
      record: order,
      fetchedAt: Date.now(),
      isPending: false,
      metadataDownloadPending: false,
    }
    if (onFetched) onFetched(rec, s)
    s.orders.records[recID] = rec
    return s
  })
}
