import { PiRegister } from "@pihanga2/core"

import { init as reducerInit } from "./orders.reducer"
import { orders, aspects } from "@pihanga2/ivcap"
import { OrderState } from "./orders.types"
import { listInit } from "./orders.list.pihanga"
import { AspectState } from "../entities/entities.types"
import { recordInit } from "./orders.record.pihanga"

export const ORDERS_ROOT = "orders"

export const enum ORDERS_CARD {
  LIST = `${ORDERS_ROOT}/list`,
  LIST_DETAIL = `${ORDERS_CARD.LIST}/detail`,
}

export function ordersInit(register: PiRegister): void {
  const ivcapAPI = orders<OrderState>(
    new URL("https://develop.ivcap.net"),
    register,
  )
  const aspectAPI = aspects<OrderState>(
    new URL("https://develop.ivcap.net"),
    register,
  )

  listInit(register)
  recordInit(register)
  reducerInit(register, ivcapAPI, aspectAPI)
}
