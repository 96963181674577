import { PiRegister } from "@pihanga2/core"

import { init as reducerInit } from "./entities.reducer"
import { aspects } from "@pihanga2/ivcap"
import { AspectState } from "./entities.types"
import { init as listInit } from "./entities.list.pihanga"
// import { init as recordInit } from "./aspects.record.pihanga"

export const ENTITIES_ROOT = "entities"

export const enum ENTITY_CARD {
  LIST = `${ENTITIES_ROOT}/list`,
  LIST_DETAIL = `${ENTITY_CARD.LIST}/detail`,
}

export function init(register: PiRegister): void {
  const ivcapAPI = aspects<AspectState>(
    new URL("https://develop.ivcap.net"),
    register,
  )

  // aspectAPI.list({}).then(({ aspects }) => {
  //   console.log("list")
  //   aspectAPI
  //     .getRecord<AppState>({ id: aspects[0].id })
  //     .reduce((s: AppState, a: AspectRecordEvent) => {
  //       console.log("record", a)
  //       return s
  //     })
  // })

  listInit(register)
  // recordInit(register)
  reducerInit(register, ivcapAPI)
}
