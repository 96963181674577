import { onInit, onShowPage, showPage } from "@pihanga2/core"
import { AspectState, AspectCard } from "./entities.types"
import {
  Aspect,
  LoadAspectListEvent,
  LoadListProps,
  URN,
  PropT,
} from "@pihanga2/ivcap"
import type { DispatchF, PiRegister } from "@pihanga2/core"
import { onPreviewAspect } from "./entities.action"
import { PageState } from "../app.state"
import { ENTITIES_ROOT } from "."

export function getList(props: LoadListProps = {}, dispatch: DispatchF) {
  const p = { ...props }
  if (p.atTime) {
    p.atTime = p.atTime.toISOString() as any // force type
  }
  showPage(dispatch, [ENTITIES_ROOT], p as any)
}

export function init(
  register: PiRegister,
  ivcapAPI: Aspect<AspectState>,
): void {
  onInit<AspectState>(register, (state) => {
    state.aspects = {
      list: { items: [], offset: 0 },
      records: {},
      data: {},
    }
    return state
  })

  onShowPage<AspectState & PageState>(
    register,
    (state, { path, query }, dispatch) => {
      if (path[0] === ENTITIES_ROOT) {
        // state.page.contentCard = AspectCard.Main
        const sa = state.aspects
        if (path.length === 1) {
          sa.recordShown = undefined
          sa.list.items = []
          const q: PropT<LoadAspectListEvent> = query || {}
          q.orderBy = "schema" // "entity"
          ivcapAPI
            .list(q)
            .reduce((s, { aspects, offset, nextPage, prevPage }) => {
              s.aspects.list = {
                items: aspects,
                offset,
                nextPage,
                prevPage,
              }
              return s
            })
          // } else if (path.length === 2) {
          //   const recID = path[1]
          //   sa.records[recID] = {
          //     ...sa.records[recID],
          //     id: recID,
          //     isPending: true,
          //   }
          //   sa.recordShown = recID
          //   ivcapAPI.getRecord({ id: recID }).reduce((s, { aspect }) => {
          //     s.aspects.records[recID] = {
          //       id: recID,
          //       record: aspect,
          //       fetchedAt: Date.now(),
          //       isPending: false,
          //     }
          //     return s
          //   })
        } else {
          // force to legal format
          showPage(dispatch, [ENTITIES_ROOT, path[1]], {})
        }
      }
      return state
    },
  )

  // onPreviewAspect<AspectState & { page: PageState }>(
  //   register,
  //   (state, { aspectID }) => {
  //     fetchAspectData(state, aspectID)
  //     state.aspects.showInModal = aspectID
  //     state.page.modalCard = "aspects/modal"
  //     return state
  //   },
  // )

  // function fetchAspectData(state: AspectState, aspectID: URN) {
  //   const el = state.aspects.data[aspectID]
  //   if (el) {
  //     el.lastAccessed = Date.now()
  //     return
  //   }
  //   state.aspects.data[aspectID] = {
  //     id: aspectID,
  //     isPending: true,
  //   }
  //   ivcapAPI.getData({ id: aspectID }).reduce((s, { data, mimeType }) => {
  //     const now = Date.now()
  //     s.aspects.data[aspectID] = {
  //       id: aspectID,
  //       data,
  //       mimeType,
  //       isPending: false,
  //       lastAccessed: now,
  //       fetchedAt: now,
  //     }
  //     return s
  //   })
  // }
  //   .getRecord({ id: aspectID }).reduce((s, rev) => {
  //     rev.aspect

  //   })

  // getData({
  //   id: "",
  //   dataURL: ""
  // })
  //   .reduce((s, { aspects, offset, nextPage, prevPage }) => {

  //   }

  // register.reducer<AppState, ReduxAction & ShowAspectInModalEvent>(
  //   ACTION_TYPES.SHOW_ASPECT_IN_MODAL,
  //   (state, ev) => {
  //     const am = { ...ev, imgURL: URL.createObjectURL(ev.data) };
  //     const s1 = update(state, ["aspectModal"], am);
  //     return update(s1, ["modal"], {
  //       modalCard: "aspectPreview",
  //     } as ModalState);
  //   }
  // );
}
