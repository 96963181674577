import { DispatchF, PiRegister, showPage } from "@pihanga2/core"
import {
  LoadListProps,
  UserInfoEvent,
  dispatchIvcapLogout,
  onIvcapUserInfo,
  onOAuthProvider,
} from "@pihanga2/ivcap"
import { AppState } from "./app.state"
import { onUserLogout } from "@pihanga2/joy-ui"
import { onBreadcrumbSelect } from "@pihanga2/cards/src/breadcrumbs"
import { ListState } from "./app.types"

export function reducerInit(register: PiRegister): void {
  onOAuthProvider<AppState>(register, (state, a) => {
    state.oauthProviders[a.id] = a
    return state
  })

  onIvcapUserInfo<AppState>(register.reducer, (state, a) => {
    const ev = { ...a } as UserInfoEvent
    delete (ev as any)["type"] // ts hack
    state.user = ev
    return state
  })

  onUserLogout<AppState>(register, (state) => {
    //state.user = undefined
    dispatchIvcapLogout(register.reducer.dispatchFromReducer)
    return state
  })

  onBreadcrumbSelect<AppState>(register, (state, { id }, dispatch) => {
    showPage(dispatch, [id])
    return state
  })
}

export function getList(
  page: string | undefined,
  list: ListState<any>,
  listRoot: string,
  dispatch: DispatchF,
) {
  const p: LoadListProps = page ? { page } : {}
  if (p.atTime) {
    p.atTime = p.atTime.toISOString() as any // force type
  }
  if (list.sortColumn) {
    p.orderBy = list.sortColumn
  }
  if (list.sortAscending !== undefined) {
    p.orderDesc = !list.sortAscending
  }
  showPage(dispatch, [listRoot], p as any)
}
