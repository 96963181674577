import { PiRegister, memo, showPage } from "@pihanga2/core"
import { FetchedOrderRecord, OrderState } from "./orders.types"
import { Table, TableColumnTypeE } from "@pihanga2/cards/src/table"
import { AspectListItem, OrderRecord } from "@pihanga2/ivcap"
import { Accordion } from "@pihanga2/cards/src"
import { AccordionSX, DEF_ACCORDION_ITEM, TableSX } from "@pihanga2/joy-ui"
import { NameValueCard, NameValueCardRowDef } from "../common.pihanga"
import { StateMapperContext } from "@pihanga2/core/src/types"
import {
  ArtifactDetailCard,
  SimplifiedArtifactItem,
  artifactList,
} from "../artifacts/artifacts.list.pihanga"
import { Typography } from "@pihanga2/cards/src/typography"
import { createOrderAspectsAction } from "./orders.action"

// id: string;
// name?: string;
// status: string;
// orderedAt: string;
// startedAt?: string;
// finishedAt?: string;
// parameters: OrderParameter[];
// products: OrderProduct[];
// service: URN;
// account: URN;
// policy?: URN;

const RecordAttributes: NameValueCardRowDef[] = [
  ["id", "ID", "string"],
  ["name", "Name", "string"],
  ["status", "Status", "string"],
  ["service", "Service", "string"],
  ["orderedAt", "Ordered At", "date"],
  ["startedAt", "Started At", "date"],
  ["finishedAt", "Finished At", "date"],
  // ["account", "Account", "string"],
  // ["policy", "Policy", "string"],
]

export function recordInit(register: PiRegister): void {
  // const summary = NameValueCard<OrderState>(RecordAttributes, (s, _ctxt) => {
  //   const id = s.route.path[1]
  //   return s.orders.records[id]
  // })
  const recMapper = (s: OrderState, _ctxt: StateMapperContext<any>) => {
    const id = s.route.path[1]
    return s.orders.records[id]
  }

  register.card(
    "orders/record",
    Accordion({
      items: memo<FetchedOrderRecord | undefined, any, OrderState, any>(
        getOrderRecord,
        (r) => [
          {
            id: "summary",
            title: "Summary",
            content: NameValueCard<OrderState, FetchedOrderRecord>(
              () => RecordAttributes,
              recMapper,
              (r) => r.record! as { [k: string]: any },
            ),
            defaultExpanded: true,
          },
          {
            id: "parameters",
            title: "Order Parameters",
            content: NameValueCard<OrderState, FetchedOrderRecord>(
              (r) => {
                const rd = (r.record?.parameters || [])
                  .filter(({ name }) => !name.startsWith("cayp"))
                  .map(({ name, value }) => {
                    return [name, name, "string"] as NameValueCardRowDef
                  })
                return rd
              },
              recMapper,
              (r) =>
                r.record!.parameters.reduce((p, { name, value }) => {
                  if (!name.startsWith("cayp")) {
                    p[name] = value
                  }
                  return p
                }, {} as { [k: string]: any }),
            ),
            defaultExpanded: true,
          },
          {
            id: "products",
            title: "Products",
            // @ts-ignore.
            // title: memo(
            //   recMapper,
            //   (r) => `Products (${(r.record?.products || DEF_EMPTY_ARRAY).length})`,
            // ),
            content: productsViewer(),
            defaultExpanded: false,
          },
          {
            id: "aspects",
            title: "Metadata",
            content: aspectsViewer(),
            context: { recordID: r?.id },
            defaultExpanded: false,
          },
        ],
      ),
      onChangedMapper: (el) => {
        if (el.itemID === "aspects") {
          return createOrderAspectsAction(el.context.recordID, el.expanded)
        } else {
          return null
        }
      },
      size: "lg",
      // @ts-ignore.
      sx: {
        items: {
          [DEF_ACCORDION_ITEM]: {
            title: {
              button: {
                display: "block",
                span: {
                  verticalAlign: "middle",
                  marginLeft: "10px",
                },
              },
            },
          },
        },
      } as AccordionSX,
    }),
  )
}

const getOrderRecord = (s: OrderState): FetchedOrderRecord | undefined => {
  const id = s.route.path[1]
  return s.orders.records[id]
}

const getOrderProducts = (s: OrderState) => {
  return (getOrderRecord(s)?.record?.products || []) as SimplifiedArtifactItem[]
}

function productsViewer() {
  return artifactList(
    getOrderProducts,
    {
      onRowClicked: (s, { row }, dispatch) => {
        showPage(dispatch, ["artifacts", row.id as string])
        return s
      },
      sheetWrap: { notUsed: true },
      hasDetails: true,
      manageDetails: true,
      cardOnEmpty: Typography({
        text: "No products",
      }),
      // @ts-ignore.
      showFooter: (s) => getOrderProducts(s as OrderState).length > 10,
      // @ts-ignore.
      sx: {
        thead: {
          "& tr th": {
            backgroundColor: "transparent",
            fontSize: "var(--joy-fontSize-xs)",
          },
        },
      } as TableSX,
    },
    ArtifactDetailCard,
    false,
  )
}

function aspectsViewer() {
  return Table<AspectListItem, OrderState>()({
    columns: [
      {
        type: TableColumnTypeE.String,
        label: "id_short",
        title: "Id",
        columnWidth: "8em",
      },
      {
        type: TableColumnTypeE.String,
        label: "schema",
        title: "Scheme",
        sortable: true,
      },
    ],
    data: memo<AspectListItem[], any, OrderState, any>(
      (s) => {
        const id = s.route.path[1]
        return s.orders.records[id]?.metadata || []
      },
      (items) =>
        items.map((el) => {
          const { id, id_short } = getAspectName(el)
          return {
            id,
            data: { ...el, id_short },
            // detailCard: DetailCard,
          }
        }),
    ),
    hideColumnHeaders: true,
    sheetWrap: { notUsed: true },
    hasDetails: true,
    manageDetails: true,
    showSearch: false,
  })
}

function getAspectName(el: AspectListItem): {
  id: string
  id_short: string
} {
  const id = el.id.split(":")[3]
  const id_short = id.substring(id.length - 6)
  return { id, id_short }
}
