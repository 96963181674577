import { memo, type PiRegister } from "@pihanga2/core"

import { DecoratorE } from "@pihanga2/cards/src"
import { Icon } from "./app.icons"
import { JyFramework } from "@pihanga2/joy-ui"
import { page } from "./page.pihanga"
import { ButtonGroup } from "@pihanga2/cards/src/buttonGroup"
import { Button } from "@pihanga2/cards/src/button"
import { JyLogin, type LoginWithProviderEvent } from "@pihanga2/joy-ui"
import { AppState, OAuthProviderListing } from "./app.state"
import { AUTH_PROVIDER_ICONS, LoginProvider } from "@pihanga2/joy-ui"
import { IVCAP_AUTH_ACTION } from "@pihanga2/ivcap"
import { Router } from "@pihanga2/cards/src/router"
import { TITLE } from "."

export enum AppCard {
  Framework = "page",
  Login = "app/login",
  Main = "app/main",

  TopBox = "app/topBox",
  TopBox2 = "app/topBox2",
  Content = "app/content",
  DataGrid = "app/data-grid",
  Header = "app/header",
  Sidebar = "app/sidebar",
  Breadcrumbs = "app/breadcrumbs",
  Menu = "app/menu",
  SecondaryMenu = "app/secondaryMenu",
  RefreshButton = "app/refresh",
  Spinner = "app/spinner",
}

export function init(register: PiRegister): void {
  register.card(
    AppCard.Framework,
    // JyFramework({ contentCard: AppCard.TopBox2 }),
    JyFramework({
      contentCard: Router<AppState>({
        contentCard: (s) => (s.user ? AppCard.Main : AppCard.Login),
      }),
    }),
  )

  register.card(
    AppCard.Login,
    JyLogin<AppState>({
      headerTitle: TITLE,
      headerIcon: Icon.Logo,
      // eslint-disable-next-line prettier/prettier
      loginProviders: memo<OAuthProviderListing, LoginProvider[], AppState, any>(
        (s) => s.oauthProviders,
        (p) =>
          Object.values(p).map((el) => ({
            id: `${el.id}|${el.ivcapURL}`,
            title: `Continue with ${el.name}`,
            logo: AUTH_PROVIDER_ICONS[el.id],
          })),
      ),
      showBackground: true,
      //withLoginForm: true,
      onWithProviderMapper: ({ providerID }: LoginWithProviderEvent) => ({
        type: IVCAP_AUTH_ACTION.LOGIN_TO_PROVIDER,
        providerID: providerID.split("|")[0],
        ivcapURL: providerID.split("|")[1],
      }),
    }),
  )

  register.card(
    AppCard.Main,
    page(
      AppCard.DataGrid,
      ButtonGroup({
        ariaLabel: "action buttons",
        spacing: 1,
        variant: "plain",
        size: "sm",
        buttons: [
          Button({
            label: "Order",
            tooltip: "Create new order",
            color: "primary",
            variant: "solid",
            startDecorator: { type: DecoratorE.Icon, icon: Icon.Add },
            onClicked: (s, a) => {
              console.log("NEW ORDER")
              return s
            },
          }),
          Button({
            label: "Download List",
            startDecorator: { type: DecoratorE.Icon, icon: Icon.Download },

            onClicked: (s, a) => {
              console.log("DOWNLOADING")
              return s
            },
          }),
        ],
      }),
    ),
  )

  // JyPageD2({
  //   title: TITLE,
  //   content: AppCard.DataGrid, // AppCard.Content,
  //   breadcrumbs: [
  //     { id: "home", decorator: { type: DecoratorE.Icon, icon: Icon.Home } },
  //     { id: "order", label: "Order" },
  //     { id: "123", label: "123" },
  //   ],
  //   logoIcon: Icon.Logo,
  //   user: {
  //     name: "Max Ott",
  //     email: "max.ott@data61.csiro.au",
  //   },
  //   actionCard: JyButtonGroup({
  //     ariaLabel: "action buttons",
  //     spacing: 1,
  //     variant: "plain",
  //     size: "sm",
  //     buttons: [
  //       JyButton({
  //         label: "Order",
  //         tooltip: "Create new order",
  //         color: "primary",
  //         variant: "solid",
  //         startDecorator: { type: DecoratorE.Icon, icon: Icon.Add },
  //         onClicked: (s, a) => {
  //           console.log("NEW ORDER")
  //           return s
  //         },
  //       }),
  //       JyButton({
  //         label: "Download List",
  //         startDecorator: { type: DecoratorE.Icon, icon: Icon.Download },

  //         onClicked: (s, a) => {
  //           console.log("DOWNLOADING")
  //           return s
  //         },
  //       }),
  //     ],
  //   }),
  //   onMenuClicked: (s, { itemID }) => {
  //     console.log("MENU ITEM CLICKED", itemID)
  //     return s
  //   },
  //   mainMenu: [
  //     {
  //       id: "home",
  //       title: "Home",
  //       startDecorator: { type: DecoratorE.Icon, icon: Icon.Home },
  //     },
  //     {
  //       id: "dashboard",
  //       title: "Dashboard",
  //       startDecorator: { type: DecoratorE.Icon, icon: Icon.Dashboard },
  //     },
  //     {
  //       id: "orders",
  //       title: "Orders",
  //       startDecorator: { type: DecoratorE.Icon, icon: Icon.Orders },
  //     },
  //     {
  //       id: "tasks",
  //       title: "Tasks",
  //       startDecorator: { type: DecoratorE.Icon, icon: Icon.Task },
  //       nested: [
  //         { id: "all_tasks", title: "All tasks" },
  //         { id: "backlog", title: "Backlog" },
  //       ],
  //     },
  //     {
  //       id: "messages",
  //       title: "Messages",
  //       startDecorator: { type: DecoratorE.Icon, icon: Icon.Message },
  //       endDecorator: { type: DecoratorE.Chip, text: "4" },
  //     },
  //   ],
  //   secondaryMenu: [
  //     {
  //       id: "support",
  //       title: "Support",
  //       startDecorator: { type: DecoratorE.Icon, icon: Icon.Support },
  //     },
  //     {
  //       id: "settings",
  //       title: "Settings",
  //       startDecorator: { type: DecoratorE.Icon, icon: Icon.Setting },
  //     },
  //   ],
  // }),
  // )

  // register.card(AppCard.Header, JyHeader({}))
  // type Row = {
  //   selected: boolean
  //   invoice: string
  //   date: string
  //   status: Status
  //   progress?: number
  // }

  // enum Status {
  //   Paid = "Paid",
  //   Refunded = "Refunded",
  //   Cancelled = "Cancelled",
  // }

  // register.card(
  //   AppCard.Content,
  //   JyTable<Row>()({
  //     hasDetails: true,
  //     manageDetails: true,
  //     columns: [
  //       {
  //         label: "id",
  //         type: TableColumnTypeE.Toggle,
  //         headerStyle: {
  //           width: "48px",
  //           textAlign: "center",
  //           padding: "12px 6px",
  //         },
  //       },
  //       {
  //         label: "invoice",
  //         type: TableColumnTypeE.String,
  //         sortable: true,
  //         headerStyle: {
  //           width: "120px",
  //           padding: "12px 6px",
  //         },
  //       },
  //       {
  //         label: "date",
  //         type: TableColumnTypeE.String,
  //         sortable: true,
  //         headerStyle: {
  //           width: "140px",
  //           padding: "12px 6px",
  //         },
  //       },
  //       chipColumnF<Status>({
  //         label: "status",
  //         startDecorator: {
  //           [Status.Paid]: { type: DecoratorE.Icon, icon: Icon.Paid },
  //           [Status.Refunded]: { type: DecoratorE.Icon, icon: Icon.Refunded },
  //           [Status.Cancelled]: {
  //             type: DecoratorE.Icon,
  //             icon: Icon.Cancelled,
  //           },
  //         },
  //         color: {
  //           [Status.Paid]: "success",
  //           [Status.Refunded]: "neutral",
  //           [Status.Cancelled]: "danger",
  //         },
  //         headerStyle: {
  //           width: "140px",
  //           padding: "12px 6px",
  //         },
  //       }),
  //       {
  //         label: "progress",
  //         type: TableColumnTypeE.Progress,
  //         headerStyle: {
  //           width: "100%",
  //           padding: "12px 6px",
  //         },
  //       },
  //     ],
  //     data: [
  //       {
  //         id: "INV-1234",
  //         data: {
  //           selected: true,
  //           invoice: "INV-1234",
  //           date: "Feb 3, 2023",
  //           status: Status.Refunded,
  //           progress: 50,
  //         },
  //         detailCard: AppCard.Menu,
  //       },
  //       {
  //         id: "INV-1233",
  //         data: {
  //           selected: true,
  //           invoice: "INV-1233",
  //           date: "Feb 3, 2023",
  //           status: Status.Paid,
  //         },
  //       },
  //       {
  //         id: "INV-1232",
  //         data: {
  //           selected: true,
  //           invoice: "INV-1232",
  //           date: "Feb 3, 2023",
  //           status: Status.Refunded,
  //         },
  //       },
  //       {
  //         id: "INV-1231",
  //         data: {
  //           selected: true,
  //           invoice: "INV-1231",
  //           date: "Feb 3, 2023",
  //           status: Status.Cancelled,
  //         },
  //         detailCard: AppCard.Menu,
  //       },
  //     ],
  //   }),
  // )

  // type DGExample = {
  //   invoice: string
  //   invoice2: string
  //   date: string
  //   date2: string
  // }

  // register.card(
  //   AppCard.DataGrid,
  //   Accordion({
  //     items: [
  //       {
  //         id: "main",
  //         title: "Datagrid Example",
  //         defaultExpanded: true,
  //         content: JyDataGrid<DGExample>()({
  //           data: {
  //             invoice: "INV-1234",
  //             invoice2: "INV-9999",
  //             date: "Feb 3, 2023",
  //             date2: "Feb 23, 2023",
  //           },
  //           items: [
  //             {
  //               id: "invoice",
  //               type: DataGridElTypeE.Text,
  //               startDecorator: { type: DecoratorE.Icon, icon: Icon.Support },
  //             },
  //             {
  //               id: "date",
  //               type: DataGridElTypeE.Date,
  //             },
  //             {
  //               id: "cancel",
  //               type: DataGridElTypeE.Link,
  //             },
  //             {
  //               id: "invoice2",
  //               type: DataGridElTypeE.Text,
  //             },
  //             { type: DataGridElTypeE.Separator },
  //             {
  //               id: "date2",
  //               type: DataGridElTypeE.Date,
  //             },
  //           ],
  //         }),
  //       },
  //     ],
  //   }),
  // )
}
