import { ReduxAction, createOnAction, registerActions } from "@pihanga2/core"
import { URN } from "@pihanga2/ivcap"

export const ARTIFACT_ACTION = registerActions("artifact", [
  "preview",
  "load_data",
  // "CREATE_NEW_PROJECT",
  // "SELECT_PROJECT",
  // "SHOW_LISTING",
  // "SHOW_INDIVIDUAL",
  // "LISTING",
  // "EDIT_PROJECT",
  // "DELETE_PROJECT",
  "error",
])

// PREVIEW

export type PreviewArtifactEvent = { artifactID: URN }

export const onPreviewArtifact = createOnAction<PreviewArtifactEvent>(
  ARTIFACT_ACTION.PREVIEW,
)

export const createPreviewAction = (
  artifactID: URN,
): ReduxAction & PreviewArtifactEvent => ({
  type: ARTIFACT_ACTION.PREVIEW,
  artifactID,
})

// // LOAD_DATA

// export type LoadArtifactDataEvent = { artifactID: URN }

// export const onLoadArtifactData = createOnAction<LoadArtifactDataEvent>(
//   ARTIFACT_ACTION.PREVIEW,
// )

// export const createLoadArtifactAction = (
//   artifactID: URN,
// ): ReduxAction & LoadArtifactDataEvent => ({
//   type: ARTIFACT_ACTION.LOAD_DATA,
//   artifactID,
// })
