import { PiRegister, ReduxAction, memo } from "@pihanga2/core"
import { ArtifactState, FetchedArtifactRecord } from "./artifacts.types"
import { Table, TableColumnTypeE, TableRow } from "@pihanga2/cards/src/table"
import { Artifact } from "@pihanga2/ivcap"
import { Accordion, AccordionItem } from "@pihanga2/cards/src"
import { Spinner } from "@pihanga2/cards/src/spinner"
import { AccordionSX, DEF_ACCORDION_ITEM } from "@pihanga2/joy-ui"
import { AccordionChangedEvent } from "@pihanga2/cards"
import { fetchArtifactContent, previewViewer } from "./artifacts.util"

type NameValue = {
  name: string
  value: any
  type: string
}

const RecordAttributes = [
  ["id", "ID", "string"],
  ["name", "Name", "string"],
  ["status", "Status", "string"],
  ["mimeType", "Mime Type", "string"],
  ["size", "Size", "number"],
  ["createdAt", "Create At", "date"],
  ["lastModifiedAt", "Last Modified", "date"],
  ["dataURL", "Data URL", "link"],
  ["policy", "Policy", "string"],
  ["account", "Account", "string"],
]

enum Section {
  Properties = "properties",
  Preview = "preview",
  Metadata = "metadata",
}

export function init(
  register: PiRegister,
  ivcapAPI: Artifact<ArtifactState>,
): void {
  const properties = Table<NameValue, ArtifactState>()({
    columns: [
      {
        type: TableColumnTypeE.String,
        label: "name",
        columnWidth: "8em",
        fontWeight: "bold",
      },
      {
        type: TableColumnTypeE.String,
        label: "value",
        sortable: true,
      },
    ],
    rowsClickable: false,
    hideColumnHeaders: true,
    sheetWrap: { notUsed: true },
    hoverRow: true,
    data: memo<
      FetchedArtifactRecord | undefined,
      TableRow<NameValue>[],
      ArtifactState,
      any
    >(getArtifactRecord, (record) => {
      if (!record || record.isPending) return []
      const rec = record.record! as any
      const rows = RecordAttributes.map(([id, name, type]) => {
        const value = rec[id] || "???"
        return {
          id: name,
          data: { name, value, type },
        }
      })
      // .sort((a, b) => a - b)
      return rows
    }),
  })

  register.card(
    "artifacts/record",
    Accordion<ArtifactState>({
      items: memo<
        FetchedArtifactRecord | undefined,
        AccordionItem[],
        ArtifactState,
        any
      >(getArtifactRecord, (record) => [
        {
          id: Section.Properties,
          title: "Properties",
          content: properties,
          defaultExpanded: true,
        },
        {
          id: Section.Preview,
          title: "Preview",
          content: previewViewer(record),
          defaultExpanded: false,
        },
        {
          id: Section.Metadata,
          title: "Metadata",
          content: Spinner({}),
          defaultExpanded: false,
        },
      ]),
      size: "lg",
      onChanged: accordionOnChanged,
      // @ts-ignore.
      sx: {
        items: {
          [DEF_ACCORDION_ITEM]: {
            title: {
              button: {
                display: "block",
                span: {
                  verticalAlign: "middle",
                  marginLeft: "10px",
                },
              },
            },
          },
        },
      } as AccordionSX,
    }),
  )

  function accordionOnChanged(
    s: ArtifactState,
    a: AccordionChangedEvent & ReduxAction,
  ) {
    const rec = getArtifactRecord(s)
    if (!rec) return s // should not happen

    switch (a.itemID) {
      case Section.Preview:
        if (rec.content && a.expanded) {
          rec.content.lastAccessedAt = Date.now()
        } else {
          fetchArtifactContent(rec.id, s, ivcapAPI)
        }
        break
      case Section.Metadata:
        // if (!rec.metadata && !rec.metadataDownloadPending) {
        //   rec.metadataDownloadPending = true
        //   ivcapAPI.getData({ id: rec.id }).reduce((s2, a) => {
        //     const r2 = getArtifactRecord(s2)
        //     if (r2) {
        //       r2.content = {
        //         id: r2.id,
        //         ...a,
        //         fetchedAt: Date.now(),
        //         lastAccessedAt: Date.now(),
        //       }
        //       r2.metadataDownloadPending = false
        //     }
        //     return s2
        //   })
        // }
        break
    }
    return s
  }
}

function getArtifactRecord(
  s: ArtifactState,
): FetchedArtifactRecord | undefined {
  const id = s.route.path[1]
  return s.artifacts.records[id]
}


//======
type AnyFunc = (...arg: any) => any

type LastFnReturnType<F extends Array<AnyFunc>, Else = never> = F extends [
  ...any[],
  (...arg: any) => infer R,
]
  ? R
  : Else

type PipeArgs<F extends AnyFunc[], Acc extends AnyFunc[] = []> = F extends [
  (...args: infer A) => infer B,
]
  ? [...Acc, (...args: A) => B]
  : F extends [(...args: infer A) => any, ...infer Tail]
  ? Tail extends [(arg: infer B) => any, ...any[]]
  ? PipeArgs<Tail, [...Acc, (...args: A) => B]>
  : Acc
  : Acc

function pipe<FirstFn extends AnyFunc, F extends AnyFunc[]>(
  firstFn: FirstFn,
  ...fns: PipeArgs<F> extends F ? F : PipeArgs<F>
): (arg: Parameters<FirstFn>[0]) => LastFnReturnType<F, ReturnType<FirstFn>> {
  return (
    arg: Parameters<FirstFn>[0],
  ): LastFnReturnType<F, ReturnType<FirstFn>> => {
    return (fns as AnyFunc[]).reduce((acc, fn) => fn(acc), firstFn(arg))
  }
}
