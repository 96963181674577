import { onInit, onShowPage, showPage } from "@pihanga2/core"
import { ArtifactListState, ArtifactState } from "./artifacts.types"
import { Artifact, LoadListProps } from "@pihanga2/ivcap"
import type { DispatchF, PiRegister } from "@pihanga2/core"
import { onPreviewArtifact } from "./artifacts.action"
import { PageState } from "../app.state"
import { fetchArtifactContent, fetchArtifactRecord } from "./artifacts.util"



// export function getList(props: LoadListProps = {}, dispatch: DispatchF) {
//   const p = { ...props }
//   if (p.atTime) {
//     p.atTime = p.atTime.toISOString() as any // force type
//   }
//   if (!p.page) delete p.page
//   showPage(dispatch, [ARTIFACT_ROOT], p as any)
// }

// export function getList(
//   page: string | undefined,
//   list: ArtifactListState,
//   dispatch: DispatchF,
// ) {
//   const p: LoadListProps = page ? { page } : {}
//   if (p.atTime) {
//     p.atTime = p.atTime.toISOString() as any // force type
//   }
//   if (list.sortColumn) {
//     p.orderBy = list.sortColumn
//   }
//   if (list.sortAscending !== undefined) {
//     p.orderDesc = !list.sortAscending
//   }
//   showPage(dispatch, [ARTIFACT_ROOT], p as any)
// }

export function init(
  register: PiRegister,
  ivcapAPI: Artifact<ArtifactState>,
): void {
  onInit<ArtifactState>(register, (state) => {
    state.artifacts = {
      list: { items: [], itemCount: 0, offset: 0 },
      records: {},
    }
    return state
  })

  onShowPage<ArtifactState & PageState>(
    register,
    (state, { path, query }, dispatch) => {
      if (path[0] === "artifacts") {
        // state.page.contentCard = ArtifactCard.Main
        const sa = state.artifacts
        if (path.length === 1) {
          sa.recordShown = undefined
          sa.list.items = []
          ivcapAPI
            .list(query ?? {})
            .reduce((s, { artifacts, firstPage, thisPage, nextPage }) => {
              let offset = -1
              if (firstPage === thisPage) {
                offset = 0
              } else if (firstPage === s.artifacts.list.firstPage) {
                const l = s.artifacts.list
                if (thisPage === l.nextPage) {
                  offset = l.offset + l.itemCount
                } else if (thisPage === l.prevPage) {
                  offset = l.offset - l.itemCount
                }
              }
              s.artifacts.list = {
                ...s.artifacts.list, // preserve order info
                items: artifacts,
                itemCount: artifacts.length,
                offset,
                firstPage,
                thisPage,
                nextPage,
                prevPage: s.artifacts.list?.thisPage,
              }
              return s
            })
        } else if (path.length === 2) {
          const recID = path[1]
          fetchArtifactRecord(recID, true, state, ivcapAPI)
        } else {
          // force to legal format
          showPage(dispatch, ["artifacts", path[1]], {})
        }
      }
      return state
    },
  )

  onPreviewArtifact<ArtifactState & { page: PageState }>(
    register,
    (state, { artifactID }) => {
      fetchArtifactContent(artifactID, state, ivcapAPI)
      state.artifacts.showInModal = artifactID
      state.page.modalCard = "artifacts/modal"
      return state
    },
  )
}
