import { start, getLogger, DEFAULT_REDUX_STATE } from "@pihanga2/core"
import { init as pihangaInit } from "./app.pihanga"
import {
  ivcapInit,
  registerIvcapDeployment,
  setAccessToken,
} from "@pihanga2/ivcap"
import { AppState } from "./app.state"

import { init as joyInit } from "@pihanga2/joy-ui"
import { init as cardInit } from "@pihanga2/cards/src"

import { init as artifactsInit } from "./artifacts"
import { init as aspectsInit } from "./entities"
import { ordersInit } from "./orders"
import { reducerInit } from "./app.reducer"

const logger = getLogger("app")

const accessToken = import.meta.env.VITE_IVCAP_JWT
if (accessToken) {
  logger.info("using local access token 'VITE_IVCAP_JWT'")
  setAccessToken(accessToken)
}

const ivcapURL = new URL(
  import.meta.env.VITE_IVCAP_URL || "https://develop.ivcap.net",
)
registerIvcapDeployment(ivcapURL)

export const TITLE = ivcapURL.hostname

const inits = [
  joyInit,
  cardInit,
  pihangaInit,
  ivcapInit,
  artifactsInit,
  aspectsInit,
  ordersInit,
  reducerInit,
]

const initState: AppState = {
  page: {},
  oauthProviders: {},
  ...DEFAULT_REDUX_STATE,
}

start(initState, inits, { disableSerializableStateCheck: true })
