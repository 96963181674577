import {
  PiDefCtxtProps,
  PiMapProps,
  PiRegister,
  ReduxState,
  createShowPageAction,
  memo,
  showPage,
} from "@pihanga2/core"
import {
  Table,
  TableColumnTypeE,
  TableEvents,
  TableProps,
} from "@pihanga2/cards/src/table"
// import { ColumnType, TbDataTable } from "../tabler/cards/tbDataTable"
import { OrderCard, OrderState } from "./orders.types"
import { OrderListItem } from "@pihanga2/ivcap"
import { ButtonGroup } from "@pihanga2/cards/src/buttonGroup"
import {
  DEF_DATE_FORMATTER,
  DataGrid,
  DataGridEl,
  DataGridElTypeE,
} from "@pihanga2/cards/src/dataGrid"
import { StateMapperContext } from "@pihanga2/core/src/types"
import { PiCardRef } from "@pihanga2/core"
import { Spinner } from "@pihanga2/cards/src/spinner"
import { Button } from "@pihanga2/cards/src/button"
import { getList } from "../app.reducer"
import { ORDERS_ROOT } from "."

export function listInit(register: PiRegister): void {
  register.card(
    OrderCard.List,
    orderList(
      (s: OrderState) => s.orders.list.items,
      {
        rowsClickable: true,
        dataOffset: (s) => s.orders.list.offset,
        // cardOnEmpty: AppCard.Spinner,
        onRowClicked: (s, { row }, dispatch) => {
          showPage(dispatch, ["orders", row.id as string])
          return s
        },
        onColumnSort: (s, { col, isAscending }, d) => {
          s.orders.list.sortColumn = col.label
          s.orders.list.sortAscending = isAscending
          getList(undefined, s.orders.list, ORDERS_ROOT, d)
          return s
        },

        onNewPage: (s, a, d) => {
          getList(a.cursor as string, s.orders.list, ORDERS_ROOT, d)
          s.orders.list.items = []
          return s
        },
      },
      OrderCard.ListDetail,
    ),
  )

  // export function listInit(register: PiRegister): void {
  //   register.card(
  //     ORDERS_CARD.LIST,
  //     Table<OrderListItem>()({
  //       columns: [
  //         {
  //           type: TableColumnTypeE.String,
  //           label: "id_short",
  //           title: "Id",
  //           columnWidth: "7em",
  //         },
  //         {
  //           type: TableColumnTypeE.String,
  //           label: "name",
  //           title: "Name",
  //           sortable: true,
  //         },
  //         {
  //           type: TableColumnTypeE.String,
  //           label: "status",
  //           title: "Status",
  //           sortable: true,
  //         },
  //         {
  //           type: TableColumnTypeE.Date,
  //           label: "orderedAt",
  //           title: "Ordered At",
  //           sortable: true,
  //         },
  //         {
  //           type: TableColumnTypeE.Date,
  //           label: "finishedAt",
  //           title: "Finished At",
  //           sortable: true,
  //         },
  //       ],
  //       noWrap: true,
  //       data: memo<OrderListItem[], any, OrderState, any>(
  //         (s) => s.orders.list.items,
  //         (items) =>
  //           items.map((el) => {
  //             const { id, id_short } = getOrderID(el)
  //             return {
  //               id,
  //               data: { ...el, id_short },
  //               detailCard: ORDERS_CARD.LIST_DETAIL,
  //             }
  //           }),
  //       ),
  //       dataOffset: (s) => s.orders.list.offset,
  //       // cardOnEmpty: AppCard.Spinner,
  //       onRowClicked: (s, { row }, dispatch) => {
  //         showPage(dispatch, [ORDERS_ROOT, row.id as string])
  //         return s
  //       },
  //       //hasDetails: true,
  //       manageDetails: true,
  //       showSearch: false,
  //       // hasMore: (s) => s.orders.list.nextPage !== null,
  //       // onNextPage: (s, _, d) => {
  //       //   getList({ page: s.orders.list.nextPage }, d)
  //       //   s.orders.list.items = []
  //       //   return s
  //       // },
  //       // onPrevPage: (s, _, d) => {
  //       //   getList({ page: s.orders.list.prevPage }, d)
  //       //   s.orders.list.items = []
  //       //   return s
  //       // },
  //     }),
  //   )


  // {
  //   id: 'urn:ivcap:order:a82cccf9-93a6-4134-a7bc-9c6e0050a344',
  //   name: 'urn:ibenthos:collection:indo_flores_0922:LB4 UQ PhotoTransect@119312',
  //   status: 'succeeded',
  //   orderedAt: '2023-10-01T05:49:44Z',
  //   startedAt: '2023-10-01T05:50:10Z',
  //   finishedAt: '2023-10-01T05:50:42Z',
  //   service: 'urn:ivcap:service:19f9c31e-8cc5-531f-8dc4-c4684fad0a33',
  //   account: 'urn:ivcap:account:45a06508-5c3a-4678-8e6d-e6399bf27538',
  //   href: 'https://develop.ivcap.net/1/orders/a82cccf9-93a6-4134-a7bc-9c6e0050a344'
  // }
  register.card(
    OrderCard.ListDetail,
    DataGrid<OrderListItem>()<OrderState>({
      data: (s, { ctxtProps }) => {
        return ctxtProps.row.data
      },
      items: memo<OrderListItem, DataGridEl[], any, PiDefCtxtProps>(
        (_, { ctxtProps }) => ctxtProps.row.data as OrderListItem,
        (row) => [
          {
            id: "name",
            type: DataGridElTypeE.Text,
            style: { root: { width: "100%" } },
          },
          { type: DataGridElTypeE.Separator },
          // {
          //   id: "id",
          //   type: DataGridElTypeE.Text,
          //   noWrap: true,
          //   //style: { root: { width: "100%" } },
          // },
          {
            id: "status",
            type: DataGridElTypeE.Text,
          },
          {
            id: "service",
            type: DataGridElTypeE.Text,
            noWrap: true,
            //style: { root: { width: "100%" } },
          },
          { type: DataGridElTypeE.Separator },
          {
            id: "orderedAt",
            type: DataGridElTypeE.Date,
          },
          {
            id: "startedAt",
            type: DataGridElTypeE.Date,
          },
          {
            id: "finishedAt",
            type: DataGridElTypeE.Date,
          },
          { type: DataGridElTypeE.Separator },
          {
            id: "account",
            type: DataGridElTypeE.Text,
            noWrap: true,
          },
          { type: DataGridElTypeE.Separator },
          {
            id: "buttons",
            type: DataGridElTypeE.Card,
            cardName: ButtonGroup({
              buttons: [
                Button({
                  label: "Details",
                  tooltip: `Show the Details page - ${row.name}`,
                  variant: "solid",
                  onClickedMapper: () =>
                    createShowPageAction(["orders", row.id]),
                }),
              ],
              ariaLabel: "buttons to manage order details card",
              size: "sm",
              spacing: "0.2em",
              variant: "plain",
              color: "primary",
            }),
            hideTitle: true,
            style: { root: { width: "100%" } },
          },
        ],
      ),
    }),
  )

  register.card(
    OrderCard.ListAction,
    ButtonGroup({
      ariaLabel: "action buttons",
      spacing: 1,
      variant: "plain",
      size: "sm",
      buttons: [
        // Button({
        //   label: "Order",
        //   tooltip: "Create new order",
        //   color: "primary",
        //   variant: "solid",
        //   startDecorator: { type: DecoratorE.Icon, icon: Icon.Add },
        //   onClicked: (s, a) => {
        //     console.log("NEW ORDER")
        //     return s
        //   },
        // }),
        // Button({
        //   label: "Download List",
        //   startDecorator: { type: DecoratorE.Icon, icon: Icon.Download },
        //   onClicked: (s, a) => {
        //     console.log("DOWNLOADING")
        //     return s
        //   },
        // }),
      ],
    }),
  )
}

export type OrderListMapper<S> = (
  state: S,
  context: StateMapperContext<any>,
) => SimplifiedOrderItem[]

export type SimplifiedOrderItem = {
  id: string
  name: string
  status: string
  orderedAt: string
  finishedAt?: string | undefined
}

export type OrderListProps<S extends ReduxState> = Omit<
  PiMapProps<
    TableProps<SimplifiedOrderItem>,
    S,
    TableEvents<SimplifiedOrderItem>
  >,
  "columns" | "data"
>

export function orderList(
  dataMapper: OrderListMapper<OrderState>,
  props: OrderListProps<OrderState>,
  detailCard: PiCardRef = OrderCard.ListDetail,
) {
  return Table<SimplifiedOrderItem, OrderState>()({
    columns: [
      {
        type: TableColumnTypeE.String,
        label: "id_short",
        title: "Id",
        columnWidth: "7em",
      },
      {
        type: TableColumnTypeE.String,
        label: "name",
        title: "Name",
        sortable: true,
      },
      {
        type: TableColumnTypeE.String,
        label: "status",
        title: "Status",
        sortable: true,
      },
      {
        type: TableColumnTypeE.Date,
        label: "orderedAt",
        title: "Ordered At",
        sortable: true,
      },
      {
        type: TableColumnTypeE.Date,
        label: "finishedAt",
        title: "Finished At",
        sortable: true,
      },
    ],
    data: memo<SimplifiedOrderItem[], any, OrderState, any>(
      dataMapper,
      (items) =>
        items.map((el) => {
          const { id, id_short, name } = getOrderName(el)
          return {
            id,
            data: { ...el, id_short, name },
            detailCard,
          }
        }),
    ),
    dataFormatter: {
      date: (el: any) => {
        return el ? DEF_DATE_FORMATTER.format(Date.parse(el)) : "-"
      },
    },
    hasDetails: true,
    manageDetails: true,
    showSearch: false,
    showFooter: true,
    thisCursor: (s) => s.orders.list.thisPage,
    firstCursor: (s) => s.orders.list.firstPage,
    nextCursor: (s) => s.orders.list.nextPage,
    prevCursor: (s) => s.orders.list.prevPage,
    cardOnEmpty: Spinner({}),
    ...props,
  })
}

function getOrderName(el: SimplifiedOrderItem): {
  id: string
  id_short: string
  name: string
} {
  const id = el.id.split(":")[3]
  const id_short = id.substring(id.length - 6)
  let name = el.name.startsWith("urn:ivcap") ? id_short : el.name
  if (name.startsWith("http")) {
    // should no longer happen
    name = id_short
  }
  return { id, id_short, name }
}
