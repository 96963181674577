import {
  PiDefCtxtProps,
  PiRegister,
  createShowPageAction,
  memo,
  showPage,
} from "@pihanga2/core"
import { Table, TableColumnTypeE } from "@pihanga2/cards/src/table"
import { ImageViewer } from "@pihanga2/cards/src"
// import { ColumnType, TbDataTable } from "../tabler/cards/tbDataTable"
import { AspectState, FetchedAspectData } from "./entities.types"
import { getList } from "./entities.reducer"
import { AppCard } from "../app.pihanga"
import { AspectListItem } from "@pihanga2/ivcap"
import { Button } from "@pihanga2/cards/src/button"
import { ButtonGroup } from "@pihanga2/cards/src/buttonGroup"
import { DecoratorE } from "@pihanga2/cards/src"
import { Icon } from "../app.icons"
import { Accordion } from "@pihanga2/cards/src/accordion"
import {
  DataGrid,
  DataGridEl,
  DataGridElTypeE,
} from "@pihanga2/cards/src/dataGrid"
import { PiCardDef, StateMapperContext } from "@pihanga2/core/src/types"
import { createPreviewAction } from "./entities.action"
import { Modal } from "@pihanga2/cards/src/modal"
import { PageState } from "../app.state"
import { ImageViewerComponent } from "@pihanga2/cards/src/imageViewer/imageViewer.component"
import { PiCardRef } from "@pihanga2/core"
import { Spinner } from "@pihanga2/cards/src/spinner"
import { ENTITIES_ROOT, ENTITY_CARD } from "."

export function init(register: PiRegister): void {
  register.card(
    ENTITY_CARD.LIST,
    Table<AspectListItem, AspectState>()({
      columns: [
        {
          type: TableColumnTypeE.String,
          label: "id_short",
          title: "Id",
          columnWidth: "7em",
        },
        {
          type: TableColumnTypeE.String,
          label: "entity",
          title: "Entity",
          sortable: true,
        },
        {
          type: TableColumnTypeE.String,
          label: "schema",
          title: "Schema",
          sortable: true,
        },
        {
          type: TableColumnTypeE.String,
          label: "contentType",
          title: "Type",
          sortable: true,
        },
      ],
      noWrap: true,
      data: memo<AspectListItem[], any, AspectState, any>(
        (s) => s.aspects.list.items,
        (items) =>
          items.map((el) => {
            const { id, id_short } = getAspectID(el)
            return {
              id,
              data: { ...el, id_short },
              detailCard: ENTITY_CARD.LIST_DETAIL,
            }
          }),
      ),
      dataOffset: (s) => s.aspects.list.offset,
      // cardOnEmpty: AppCard.Spinner,
      onRowClicked: (s, { row }, dispatch) => {
        showPage(dispatch, [ENTITIES_ROOT, row.id as string])
        return s
      },
      // dataFormatter: {
      //   'date': (el: Date) => dataFormatter.format(el),
      // },
      hasDetails: true,
      manageDetails: true,
      showSearch: false,
      // hasMore: (s) => s.aspects.list.nextPage !== null,
      // onNextPage: (s, _, d) => {
      //   getList({ page: s.aspects.list.nextPage }, d)
      //   s.aspects.list.items = []
      //   return s
      // },
      // onPrevPage: (s, _, d) => {
      //   getList({ page: s.aspects.list.prevPage }, d)
      //   s.aspects.list.items = []
      //   return s
      // },
    }),
  )

  register.card(
    ENTITY_CARD.LIST_DETAIL,
    DataGrid<AspectListItem>()<AspectState>({
      data: (s, { ctxtProps }) => {
        return ctxtProps.row.data
      },
      items: memo<AspectListItem, DataGridEl[], any, PiDefCtxtProps>(
        (_, { ctxtProps }) => ctxtProps.row.data as AspectListItem,
        (row) => [
          {
            id: "id",
            type: DataGridElTypeE.Text,
            //startDecorator: { type: DecoratorE.Icon, icon: Icon.Support },
            style: { root: { width: "100%" } },
          },
          { type: DataGridElTypeE.Separator },
          {
            id: "entity",
            type: DataGridElTypeE.Text,
            noWrap: true,
          },
          {
            id: "schema",
            type: DataGridElTypeE.Text,
            noWrap: true,
          },
          {
            id: "contentType",
            title: "Content Type",
            type: DataGridElTypeE.Text,
          },
          // { type: DataGridElTypeE.Separator },
          // {
          //   id: "buttons",
          //   type: DataGridElTypeE.Card,
          //   cardName: ButtonGroup({
          //     buttons: [
          //       Button<AspectState>({
          //         label: "Preview",
          //         tooltip: "Preview aspect in popup",
          //         variant: "solid",
          //         onClickedMapper: () => createPreviewAction(row.id),
          //       }),
          //       Button({
          //         label: "Details",
          //         tooltip: `Show the Details page - ${row.name}`,
          //         variant: "plain",
          //         onClickedMapper: () =>
          //           createShowPageAction(["aspects", row.id]),
          //       }),
          //     ],
          //     ariaLabel: "buttons to manage aspect details card",
          //     size: "sm",
          //     spacing: "0.2em",
          //     variant: "plain",
          //     color: "primary",
          //   }),
          //   hideTitle: true,
          //   style: { root: { width: "100%" } },
          // },
        ],
      ),
    }),
  )

  register.card(
    "aspects/list/actions",
    ButtonGroup({
      ariaLabel: "action buttons",
      spacing: 1,
      variant: "plain",
      size: "sm",
      buttons: [
        // Button({
        //   label: "Order",
        //   tooltip: "Create new order",
        //   color: "primary",
        //   variant: "solid",
        //   startDecorator: { type: DecoratorE.Icon, icon: Icon.Add },
        //   onClicked: (s, a) => {
        //     console.log("NEW ORDER")
        //     return s
        //   },
        // }),
        // Button({
        //   label: "Download List",
        //   startDecorator: { type: DecoratorE.Icon, icon: Icon.Download },
        //   onClicked: (s, a) => {
        //     console.log("DOWNLOADING")
        //     return s
        //   },
        // }),
      ],
    }),
  )

  register.card(
    "aspects/modal",
    Modal<AspectState & PageState>({
      title: (s) => {
        const id = s.aspects.showInModal
        if (!id) return "???"
        const rec = s.aspects.list.items.find((r) => r.id === id)
        if (!rec) return "???"
        return getAspectID(rec).id
      },
      open: (s) => !!s.aspects.showInModal,
      contentCard: memo<
        FetchedAspectData | undefined,
        PiCardRef,
        AspectState,
        any
      >(
        (s) => {
          return s.aspects.data[s.aspects.showInModal || ""]
        },
        (rec) => {
          if (!rec || !rec.data) return Spinner({})
          return ImageViewer({
            imgURL: URL.createObjectURL(rec.data),
            caption: "",
          })
        },
      ),
      onClose: (s) => {
        s.page.modalCard = undefined
        return s
      },
    }),
  )
  // showInModal

  // register.card(AspectCard.Upload, PiFileDrop<AspectState>({})
}

function getAspectID(el: AspectListItem): {
  id: string
  id_short: string
} {
  const id = el.id.split(":")[3]
  const id_short = id.substring(id.length - 6)
  return { id, id_short }
}
