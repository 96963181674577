import { PiCardRef } from "@pihanga2/core"
import { ArtifactState, FetchedArtifactRecord } from "./artifacts.types"
import { Spinner } from "@pihanga2/cards/src/spinner"
import { ImageViewer, JsonViewer } from "@pihanga2/cards"
import { Artifact } from "@pihanga2/ivcap"

export function previewViewer(
  record: FetchedArtifactRecord | undefined,
): PiCardRef {
  const content = record?.content
  if (!content) {
    return Spinner({})
  }
  const mt = content.mimeType
  if (mt.startsWith("image")) {
    return ImageViewer({
      imgURL: content.data as string,
      caption: "???",
    })
  } else if (mt === "application/json") {
    return JsonViewer({
      source: content.data,
    })
  } else {
    return Spinner({})
  }
}

export function fetchArtifactRecord(
  recID: string,
  refresh: boolean, // if set, fetch record even if it already exists
  state: ArtifactState,
  ivcapAPI: Artifact<ArtifactState>,
  onFetched?: (rec: FetchedArtifactRecord, state: ArtifactState) => void,
) {
  let rec = state.artifacts.records[recID]
  if (rec && (!refresh || rec.isPending)) {
    // already fetched or in the process of fetching
    return
  }
  // ok, fetch record from backend
  rec = {
    id: recID,
    isPending: true,
    contentDownloadPending: false,
    metadataDownloadPending: false,
  }
  state.artifacts.records[recID] = rec
  ivcapAPI.getRecord({ id: recID }).reduce((s, { artifact }) => {
    const rec: FetchedArtifactRecord = {
      id: recID,
      record: artifact,
      fetchedAt: Date.now(),
      isPending: false,
      contentDownloadPending: false,
      metadataDownloadPending: false,
    }
    if (onFetched) onFetched(rec, s)
    s.artifacts.records[recID] = rec
    return s
  })
}

export function fetchArtifactContent(
  recID: string,
  state: ArtifactState,
  ivcapAPI: Artifact<ArtifactState>,
) {
  const rec = state.artifacts.records[recID]
  if (!rec) {
    // download record first and then try again
    fetchArtifactRecord(recID, false, state, ivcapAPI, (_, s) => {
      fetchArtifactContent(recID, s, ivcapAPI)
    })
    return
  }

  if (!rec.content && !rec.contentDownloadPending) {
    rec.contentDownloadPending = true
    const id = rec.id // 'rec' won't stay accessible in context (proxy)
    ivcapAPI.getData({ id: rec.id }).reduce((s2, a) => {
      const r2 = s2.artifacts.records[id]
      if (r2) {
        r2.content = {
          id: r2.id,
          ...a,
          fetchedAt: Date.now(),
          lastAccessedAt: Date.now(),
        }
        r2.contentDownloadPending = false
      }
      return s2
    })
  }
}
