import { onInit, onShowPage, showPage } from "@pihanga2/core"
import { OrderState, OrderCard, OrderListState } from "./orders.types"
import {
  Order,
  LoadOrderListEvent,
  LoadListProps,
  URN,
  PropT,
  Aspect,
  AspectListEvent,
} from "@pihanga2/ivcap"
import type { DispatchF, PiRegister } from "@pihanga2/core"
import { onPreviewOrder } from "./orders.action"
import { PageState } from "../app.state"
import { ORDERS_ROOT } from "."
import { AspectState } from "../entities/entities.types"
import { fetchOrderRecord } from "./order.util"


export function init(
  register: PiRegister,
  orderAPI: Order<OrderState>,
  aspectAPI: Aspect<OrderState>,
): void {
  onInit<OrderState>(register, (state) => {
    state.orders = {
      list: { items: [], itemCount: 0, offset: 0 },
      records: {},
    }
    return state
  })

  onShowPage<OrderState & PageState>(
    register,
    (state, { path, query }, dispatch) => {
      if (path[0] === ORDERS_ROOT) {
        // state.page.contentCard = OrderCard.Main
        const sa = state.orders
        if (path.length === 1) {
          sa.recordShown = undefined
          sa.list.items = []
          const q: PropT<LoadOrderListEvent> = query || {}
          q.orderBy = "ordered-at"
          orderAPI
            .list(q)
            .reduce((s, { orders, firstPage, thisPage, nextPage }) => {
              let offset = -1
              if (firstPage === thisPage) {
                offset = 0
              } else if (firstPage === s.orders.list.firstPage) {
                const l = s.orders.list
                if (thisPage === l.nextPage) {
                  offset = l.offset + l.itemCount
                } else if (thisPage === l.prevPage) {
                  offset = l.offset - l.itemCount
                }
              }
              s.orders.list = {
                ...s.orders.list, // preserve order info
                items: orders,
                offset,
                firstPage,
                thisPage,
                nextPage,
                prevPage: s.orders.list?.thisPage,
              }
              return s
            })
        } else if (path.length === 2) {
          const recID = path[1]
          fetchOrderRecord(recID, true, state, orderAPI)

          // sa.records[recID] = {
          //   ...sa.records[recID],
          //   id: recID,
          //   isPending: true,
          // }
          // sa.recordShown = recID
          // orderAPI.get({ id: recID }).reduce((s, { order }) => {
          //   s.orders.records[recID] = {
          //     ...s.orders.records[recID],
          //     id: recID,
          //     record: order,
          //     fetchedAt: Date.now(),
          //     isPending: false,
          //   }
          //   return s
          // })
          // const entity = recID.startsWith("urn:")
          //   ? recID
          //   : `urn:ivcap:order:${recID}`
          // aspectAPI.list({ entity }).reduce((s, { aspects }) => {
          //   s.orders.records[recID] = {
          //     ...s.orders.records[recID],
          //     aspects,
          //   }
          //   return s
          // })
        } else {
          // force to legal format
          showPage(dispatch, [ORDERS_ROOT, path[1]], {})
        }
      }
      return state
    },
  )

  // onPreviewOrder<OrderState & { page: PageState }>(
  //   register,
  //   (state, { orderID }) => {
  //     fetchOrderData(state, orderID)
  //     state.orders.showInModal = orderID
  //     state.page.modalCard = "orders/modal"
  //     return state
  //   },
  // )

  // function fetchOrderData(state: OrderState, orderID: URN) {
  //   const el = state.orders.data[orderID]
  //   if (el) {
  //     el.lastAccessed = Date.now()
  //     return
  //   }
  //   state.orders.data[orderID] = {
  //     id: orderID,
  //     isPending: true,
  //   }
  //   ivcapAPI.getData({ id: orderID }).reduce((s, { data, mimeType }) => {
  //     const now = Date.now()
  //     s.orders.data[orderID] = {
  //       id: orderID,
  //       data,
  //       mimeType,
  //       isPending: false,
  //       lastAccessed: now,
  //       fetchedAt: now,
  //     }
  //     return s
  //   })
  // }
  //   .getRecord({ id: orderID }).reduce((s, rev) => {
  //     rev.order

  //   })

  // getData({
  //   id: "",
  //   dataURL: ""
  // })
  //   .reduce((s, { orders, offset, nextPage, prevPage }) => {

  //   }

  // register.reducer<AppState, ReduxAction & ShowOrderInModalEvent>(
  //   ACTION_TYPES.SHOW_ORDER_IN_MODAL,
  //   (state, ev) => {
  //     const am = { ...ev, imgURL: URL.createObjectURL(ev.data) };
  //     const s1 = update(state, ["orderModal"], am);
  //     return update(s1, ["modal"], {
  //       modalCard: "orderPreview",
  //     } as ModalState);
  //   }
  // );
}
