import { PiRegister } from "@pihanga2/core"

import { init as reducerInit } from "./artifacts.reducer"
import { artifacts } from "@pihanga2/ivcap"
import { ArtifactState } from "./artifacts.types"
import { init as listInit } from "./artifacts.list.pihanga"
import { init as recordInit } from "./artifacts.record.pihanga"

export function init(register: PiRegister): void {
  const ivcapAPI = artifacts<ArtifactState>(
    new URL("https://develop.ivcap.net"),
    register,
  )

  listInit(register)
  recordInit(register, ivcapAPI)
  reducerInit(register, ivcapAPI)
}
